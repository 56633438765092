<template>
  <div class="video-wrapper">
    <div class="video-top">
      <van-nav-bar
        title="监控视频"
        left-arrow
        :border="false"
        @click-left="onClickLeft"
      >
        <template #right>
          <i
            class="filtericon iconfont iconfont-shaixuan"
            @click="showFilter(true)"
          ></i>
        </template>
      </van-nav-bar>

      <van-search
        v-model="searchName"
        placeholder="请输入摄像头名称或IP关键字"
        class="search-bar"
        @search="onSearch"
        @clear="onCancel"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>
      <div class="video-total">共{{ total }}路监控</div>
    </div>
    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="video-list"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText || '没有更多了'"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div
          v-for="item in videoList"
          :key="item.id"
          class="video-item"
          @click="gotoVideoDetail(item.id)"
        >
          <div class="video-icon" :class="{ active: item.onLine }">
            <i
              class="iconfont iconfont-shipin1"
              @click.stop="playLine(item)"
            ></i>
          </div>
          <div class="video-name">
            {{ item.videoName }}
          </div>
          <div class="video-other-item">
            <span v-if="item.departmentName" class="video-part"
              ><i class="iconfont iconfont-bumen"></i>
              {{ item.departmentName }}</span
            >
            <span v-if="item.relatedDevice" class="device-info"
              ><i class="iconfont iconfont-jianceduixiang"></i
              >{{
                item.relatedDevice ? item.relatedDevice.join(",") : ""
              }}</span
            >
            <span
              v-if="item.levelCode"
              class="danger-source"
              :class="dangerLevelObj[item.levelCode]"
              >危险源</span
            >
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-popup
      v-model="visible"
      :close-on-click-overlay="false"
      position="bottom"
      :style="popupStyle"
      class="filter-popup"
      @open="onOpen"
      @close="onClose"
      @click-overlay="onClose"
    >
      <section class="select-popup-container">
        <div class="header">
          <i
            class="closeicon iconfont iconfont-mengcengguanbi"
            @click="showFilter(false)"
          ></i>
          <h3 class="popup-title">监控视频筛选</h3>
        </div>
        <div class="content">
          <van-cell-group>
            <van-cell
              title="重大危险源"
              is-link
              :value="form.dangerName || '请选择'"
              @click="selectCon(1)"
            />
            <van-cell
              title="监控对象"
              is-link
              :value="form.poiName || '请选择'"
              @click="selectCon(2)"
            />
            <van-cell
              title="所属部门"
              is-link
              :value="form.departmentName || '请选择'"
              @click="selectCon(3)"
            />
            <van-cell
              title="网络连接状态"
              is-link
              :value="form.onLineName || '请选择'"
              @click="selectCon(4)"
            />
            <van-cell
              title="是否地图标点"
              is-link
              :value="form.poiSpaceFlagName || '请选择'"
              @click="selectCon(5)"
            />
          </van-cell-group>
        </div>
        <div class="footer">
          <div class="btn-group">
            <van-button block @click.prevent="onReset">重置</van-button>
            <van-button block type="info" @click.prevent="onConfirm"
              >确定</van-button
            >
          </div>
        </div>
      </section>
    </van-popup>
    <SelectPopupGrade
      ref="selectPopupGrade1"
      v-model="popupVisibleObj[1].value"
      list-key="value"
      :other-value.sync="form.dangerName"
      :title="popupVisibleObj[1].title"
      :list.sync="popupVisibleObj[1].list"
      :visible="popupVisibleObj[1].visiable"
      @change="v => popupGradeChange(v, 1)"
      @close="popupVisibleObj[1].visiable = false"
    />
    <WorkAreaPicker
      ref="workAreaPicker"
      v-model="form.poiName"
      :title="popupVisibleObj[2].title"
      @change="(id, label) => popupGradeChange(id, 2, label)"
    />
    <cascade-department
      v-model="form.departmentId"
      include-children
      :visible="popupVisibleObj[3].visiable"
      @confirm="confirmDepartment"
      @close="popupVisibleObj[3].visiable = false"
    ></cascade-department>
    <VideoPopup v-model="showVideo" :model="model" />

    <select-popup
      :choose-value="query.onLine"
      :data="onLineSelects"
      title="网络连接状态"
      :visible="popupVisibleObj[4].visiable"
      @close="popupVisibleObj[4].visiable = false"
      @confirm="onOnlineConfirm"
    />

    <select-popup
      :choose-value="query.poiSpaceFlag"
      :data="poiSpaceFlagSelect"
      title="是否地图标点"
      :visible="popupVisibleObj[5].visiable"
      @close="popupVisibleObj[5].visiable = false"
      @confirm="onPoiSpaceFlagConfirm"
    />
  </div>
</template>

<script>
import { getDangerSelect, getVideoList } from "@/api/base";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import WorkAreaPicker from "@/components/picker/WorkAreaPicker";
import { mapState } from "vuex";
import VideoPopup from "./components/videoPopup";
import SelectPopup from "@/components/SelectPopup";
export default {
  name: "VideoList",
  components: {
    SelectPopupGrade,
    WorkAreaPicker,
    VideoPopup,
    SelectPopup
  },
  data() {
    return {
      showVideo: false,
      visible: false,
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      searchName: "",
      finishedText: "",
      total: 0,
      model: {
        videoName: "",
        orgCode: "",
        nvrAddr: "",
        channelAddr: "",
        cameraIP: ""
      },
      dangerLevelObj: {
        100311: "level1",
        100312: "level2",
        100313: "level3",
        100314: "level4"
      },
      videoList: [],
      popupStyle: {
        height: "100%"
      },
      query: {
        page: 1,
        size: 10,
        dangerIdList: "", // 危险源id
        poiIdList: "", // 设施id
        departments: "", // 部门id
        subDepartment: true,
        videoName: "", // 摄像头名称
        orgCode: "",
        onLine: "",
        poiSpaceFlag: ""
      },
      form: {
        dangerName: "",
        poiName: "",
        departmentName: "",
        departmentId: "",
        onLineName: "",
        poiSpaceFlagName: ""
      },
      popupVisibleObj: {
        1: {
          visiable: false,
          title: "重大危险源",
          list: []
        },
        2: {
          visiable: false,
          title: "监控对象"
        },
        3: {
          visiable: false,
          title: "所属部门"
        },
        4: {
          visiable: false,
          title: "网络连接状态"
        },
        5: {
          visiable: false,
          title: "是否地图标点"
        }
      }
    };
  },
  created() {
    this.getSelect();
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    onLineSelects() {
      return [
        {
          label: "在线",
          value: "1"
        },
        {
          label: "离线",
          value: "0"
        }
      ];
    },
    poiSpaceFlagSelect() {
      return [
        {
          label: "已标点",
          value: "1"
        },
        {
          label: "未标点",
          value: "0"
        }
      ];
    }
  },
  activated() {
    this.$nextTick(() => {
      if (this.$refs.vanList && this.$refs.vanList.$el) {
        this.$refs.vanList.$el.scrollTo(0, this.scrollHeight || 0);
      }
    });
  },
  methods: {
    playLine(item) {
      const obj = {
        ...item,
        orgCode: this.userInfo.orgCode
      };
      this.model = obj;

      this.showVideo = true;
    },
    mergeParams(obj = {}) {
      return {
        ...obj,
        orgCode: this.userInfo.orgCode
      };
    },
    getList() {
      this.finished = false;
      getVideoList(
        this.mergeParams({
          ...this.query,
          keyInfo: this.searchName
        })
      ).then(res => {
        this.loading = false;
        if (res.list && res.list.length) {
          this.total = res.total;
          this.videoList = this.videoList.concat(res.list);
          this.query.page++;
        } else {
          if (!this.videoList.length) {
            this.finishedText = "没有更多了";
          } else {
            this.finishedText = "";
          }
          this.finished = true;
        }
      });
    },
    onLoad() {
      if (this.refreshing) {
        this.videoList = [];
        this.refreshing = false;
      }
      this.getList();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    confirmDepartment(departObj, includeInfo) {
      this.query.departments = departObj.id;
      this.query.subDepartment = includeInfo?.include;
      this.form.departmentName = departObj.label;
      // this.query.departments = departObj.id;
    },
    popupGradeChange(v, type, label) {
      const vObj = v[0];
      if (type === 1) {
        // 危险源
        // 如果是全部危险源
        if (vObj && vObj.value === -1) {
          this.query.dangerIdList = this.popupVisibleObj[type].list
            .filter(a => a.value !== -1)
            .map(a => a.value)
            .join(",");
        } else {
          this.query.dangerIdList = v.map(a => a.value).join(",");
        }
        this.popupVisibleObj[1].value = this.query.dangerIdList;
      } else if (type === 2) {
        // 设备设施
        this.query.poiIdList = v;
        this.form.poiName = label;
      }
    },
    onOnlineConfirm(row) {
      this.query.onLine = row.value;
      this.form.onLineName = row.label;
    },
    onPoiSpaceFlagConfirm(row) {
      this.query.poiSpaceFlag = row.value;
      this.form.poiSpaceFlagName = row.label;
    },
    getSelect() {
      getDangerSelect().then(res => {
        if (res && res.length) {
          this.popupVisibleObj[1].list = [
            { label: "全部危险源", value: -1 },
            ...res
          ];
        } else {
          this.popupVisibleObj[1].list = [];
        }
      });
    },
    gotoVideoDetail(id) {
      this.$router.push({ name: "VideoDetail", params: { id } });
    },
    selectCon(type) {
      if (type === 2) {
        this.$refs.workAreaPicker.show();
      } else {
        this.popupVisibleObj[type].visiable = true;
      }
    },
    onSearch(val) {
      this.finished = false;
      // this.handleCondition();
      this.loading = true;
      this.query.page = 1;
      this.videoList = [];
      this.total = 0;
      this.getList();
    },
    onCancel() {
      this.finished = false;
    },
    showFilter(v) {
      this.visible = v;
    },
    onClickLeft() {
      history.go(-1);
    },
    onReset() {
      this.query.page = 1;
      this.query.size = 10;
      this.query.dangerIdList = ""; // 危险源id
      this.query.poiIdList = ""; // 设施id
      this.query.departments = ""; // 部门id
      this.query.subDepartment = true;
      this.query.videoName = ""; // 摄像头名称
      this.query.orgCode = "";
      this.query.onLine = "";
      this.query.poiSpaceFlag = "";

      this.form.dangerName = "";
      this.form.poiName = "";
      this.form.departmentName = "";
      this.popupVisibleObj[1].value = "";
      this.form.departmentId = "";
      this.form.onLineName = "";
      this.form.poiSpaceFlagName = "";
      this.$refs.selectPopupGrade1.setActive("");
    },
    onConfirm() {
      // 关闭弹窗
      this.showFilter(false);
      // 执行查询
      this.onSearch();
    },
    onOpen() {},
    onClose() {}
  },
  beforeRouteLeave(to, from, next) {
    this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    next();
  }
};
</script>

<style lang="scss" scoped>
.video-wrapper {
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  .video-top {
    background-color: #fff;
    .video-total {
      text-align: center;
      font-size: 12px;
      color: #8c8f97;
      font-weight: 400;
    }
    .filtericon {
      font-size: 24px;
    }
  }
  .filter-popup {
    .select-popup-container {
      .header {
        position: relative;
        height: 44px;
        line-height: 44px;
        text-align: center;
        .closeicon {
          position: absolute;
          left: 0;
          top: 0;
          width: 44px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          color: #9496a3;
          font-size: 15px;
        }
        .popup-title {
          color: #2e2e4d;
          font-size: 18px;
          margin: 0 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .content {
        padding: 10px 0;
        .van-cell__title {
          flex: none;
          width: 100px;
        }
      }
      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .btn-group {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          button:first-of-type {
            margin-right: 13px;
          }
        }
      }
    }
  }
  .video-list {
    flex: 1;
    overflow-y: auto;
    .video-item {
      padding: 11px 16px 13px 16px;
      position: relative;
      border-bottom: 8px solid #eceef2;
      .video-icon {
        position: absolute;
        right: 16px;
        top: 8px;
        .iconfont {
          color: #d9dbe1;
          font-size: 24px;
        }
        &.active {
          .iconfont {
            color: #1676ff;
          }
        }
      }
      .video-name {
        color: #3b4664;
        font-size: 14px;
        font-weight: 500;
        padding-right: 30px;
        line-height: 16px;
      }
      .video-other-item {
        margin-top: 10px;
        color: #3b4664;
        font-size: 12px;
        span:first-child {
          margin-right: 24px;
        }
        .device-info {
          display: inline-block;
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: top;
        }
        .iconfont {
          margin-right: 2px;
          color: #aeb3c0;
        }
        .danger-source {
          position: relative;
          display: inline-block;
          transform: scale(0.9);
          padding: 2px 4px;
          font-size: 12px;
          margin-left: 4px;
          vertical-align: top;
          &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 200%;
            height: 200%;
            border-radius: 2px;
            transform: scale(0.5);
            transform-origin: 0 0;
          }
          &.level1 {
            &::after {
              border: 1px solid #f13030;
            }
            color: #f13030;
            background-color: #ffdddd;
          }
          &.level2 {
            &::after {
              border: 1px solid #ff6d1e;
            }
            color: #ff6d1e;
            background-color: #ffe7db;
          }
          &.level3 {
            &::after {
              border: 1px solid #edc400;
            }
            color: #edc400;
            background-color: #fff8d7;
          }
          &.level4 {
            &::after {
              border: 1px solid #1580ff;
            }
            color: #1580ff;
            background-color: #dcecff;
          }
        }
      }
    }
  }
}
</style>
