<template>
  <van-popup
    class="video-popup"
    :style="{ 'min-height': '50vw', width: '95vw' }"
    v-bind="$attrs"
    @click-overlay="onClose"
    @opened="onOpened"
  >
    <span
      class="closeecharts iconfont iconfont-danchuangguanbi"
      @click.stop="onClose"
    ></span>
    <div class="popup-video-title">{{ model.videoName }}</div>
    <div class="video-content">
      <div class="video-box">
        <ty-live-player
          v-if="videoLiveVisible"
          request-mode="preview"
          :camera-i-p="cameraIP"
          :org-code="orgCode"
          :nvr-addr="nvrAddr"
          :channel-id="channelAddr"
          :camera-type="cameraType"
        />
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  components: {
    TyLivePlayer: () => import("@ty-live-player")
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      videoLiveVisible: false,
      orgCode: "",
      cameraIP: "",
      nvrAddr: "",
      channelAddr: "",
      cameraType: ""
    };
  },
  methods: {
    onClose() {
      this.showVideo(false);
    },
    onOpened() {
      this.showLiveVideo();
    },
    showLiveVideo() {
      this.orgCode = this.model.orgCode;
      this.nvrAddr = this.model.nvrAddr;
      this.channelAddr = this.model.channelAddr;
      this.cameraIP = this.model.videoIpAddr;
      this.cameraType = this.model.cameraType;
      let hasVideoData = this.nvrAddr && this.channelAddr;
      if (!hasVideoData) {
        this.$dialog.alert({
          message: "暂无视频数据"
        });
        return false;
      }
      this.videoLiveVisible = true;
    },
    showVideo(v) {
      if (!v) {
        this.videoLiveVisible = false;
      }
      this.$emit("change", v);
    }
  }
};
</script>

<style lang="scss" scoped>
.video-popup {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding-top: 18px;
  .closeecharts {
    position: absolute;
    font-size: 18px;
    top: 8px;
    right: 8px;
    color: #9496a3;
  }
  .popup-video-title {
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
    color: #3b4664;
  }
  .video-content {
    flex: 1;
    padding: 16px;
    .video-box {
      height: 100%;
      min-height: 180px;
      background-color: #a8a8a8;
      .live-player-box {
        height: 100%;
        ::v-deep .vjs-fullscreen-control {
          display: none;
        }
      }
    }
  }
}
</style>
